<template>
  <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
    <div class="ui-layout-content--full ui-form-actions">
      <el-button type="primary" size="mini" @click="addHandle" v-if="$authCheck('business:electricityPrice:save')" style="border-radius: 4px; margin: 20px 20px 0px 20px">+ 新建</el-button>
<!--      <el-button type="primary" size="mini" @click="editHandle">编辑</el-button>-->
<!--      <el-button type="primary" size="mini" @click="showDetail">详情</el-button>-->
<!--      <el-button type="primary" size="mini" @click="deleteHandle">删除</el-button>-->
    </div>
    <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0 20px 20px 20px">
      <ex-search-table-pagination
        ref="searchTable"
        :fetch="$client.pageElectricityPrice"
        :columns="columns"
        :formOptions="formOptions"
        @selection-change="selectHandle"
      >
          <template slot="append">
              <el-table-column
                  align="center"
                  label="操作"
                  type="action"
                  width="160"
                  fixed="right"
              >
                  <template slot-scope="scope">
                      <el-tooltip content="详情" placement="top" :open-delay="200" v-if="$authCheck('business:electricityPrice:info')">
                          <div
                              class="search-table-icon search-table-icon_reset_password"
                              @click="showDetail(scope.row.id)"
                              type="text"
                          ></div>
                      </el-tooltip>
                      <el-tooltip content="编辑" placement="top" :open-delay="200" v-if="$authCheck('business:electricityPrice:update')">
                          <div
                              class="search-table-icon search-table-icon_edit"
                              @click="editHandle(scope.row.id)"
                              type="text"
                          ></div>
                      </el-tooltip>
                      <el-tooltip content="删除" placement="top" :open-delay="200" v-if="$authCheck('business:electricityPrice:delete')">
                          <div
                              class="search-table-icon search-table-icon_delete"
                              @click="deleteHandle(scope.row.id)"
                              type="text"
                          ></div>
                      </el-tooltip>
                  </template>
              </el-table-column>
          </template>
      </ex-search-table-pagination>
    </div>
    <el-dialog
      width="960px"
      :title="dialog.title"
      v-if="dialog.visible"
      :visible.sync="dialog.visible"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      :append-to-body="true"
      :show-close="true"
    >
      <electricity-price-form ref="editForm" :id="dialog.currentId" :type="dialog.type"></electricity-price-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModalHandle" size="mini">
            {{dialog.type === 'detail' ? '关闭' : '取消'}}
        </el-button>
        <el-button
          type="primary"
          @click="submitHandle"
          size="mini"
          v-if="dialog.type !== 'detail'"
        >确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElectricityPriceForm from './Form';
export default {
    name: 'ElectricityPrice',
    components: {
        ElectricityPriceForm
    },
    data() {
        const monthOptions = [];
        for (let i = 0; i < 12; i++) {
            monthOptions.push({key: i + 1, value: i + 1 + '月'});
        }
        return {
            columns: [
                /*{
                    type: 'selection'
                },*/
                {
                    prop: 'month',
                    label: '月份',
                    formatter: (row, column, value) => {
                        return `${value} 月`;
                    }
                },
                {
                    prop: 'waterMark',
                    label: '丰枯标志',
                    formatter: (row, column, value) => {
                        switch (value) {
                            case 1:
                                return '枯水期';
                            case 2:
                                return '丰水期';
                            case 3:
                                return '平水期';
                            default:
                                return `未知丰枯标志${value}`;
                        }
                    }
                },
                {
                    prop: 'elecType',
                    label: '用电分类',
                    width: 100,
                    formatter: (row, column, value) => {
                        if (value === 1) {
                            return '大工业用电';
                        }
                        if (value === 0) {
                            return '一般工商业及其他用电';
                        }
                        return `未知用电分类(${value})`;
                    }
                },
                {
                    prop: 'period',
                    label: '计费时段',
                    width: 100,
                    renderHtml: () => {
                        return `峰时<br/>平时<br/>谷时<br/>`;
                    }
                },
                {
                    prop: 'priceOne',
                    label: '不满1kv',
                    renderHtml: (row) => {
                        return `${row.peakPriceOne || ''}<br/>${row.flatPriceOne || ''}<br/>${row.valleyPriceOne || ''}<br/>`;
                    }
                },
                {
                    prop: 'priceTwo',
                    label: '1-10kv',
                    renderHtml: (row) => {
                        return `${row.peakPriceTwo || ''}<br/>${row.flatPriceTwo || ''}<br/>${row.valleyPriceTwo || ''}<br/>`;
                    }
                },
                {
                    prop: 'priceThree',
                    label: '35-110kv',
                    renderHtml: (row) => {
                        return `${row.peakPriceThree || ''}<br/>${row.flatPriceThree || ''}<br/>${row.valleyPriceThree || ''}<br/>`;
                    }
                },
                {
                    prop: 'priceFour',
                    label: '110kv',
                    renderHtml: (row) => {
                        return `${row.peakPriceFour || ''}<br/>${row.flatPriceFour || ''}<br/>${row.valleyPriceFour || ''}<br/>`;
                    }
                },
                {
                    prop: 'priceFive',
                    label: '220kv及以上',
                    renderHtml: (row) => {
                        return `${row.peakPriceFive || ''}<br/>${row.flatPriceFive || ''}<br/>${row.valleyPriceFive || ''}<br/>`;
                    }
                },
                {
                    prop: 'maxDemandBasePrice',
                    label: '最大需量(元/千瓦·月)',
                },
                {
                    prop: 'transformerCapacityBasePrice',
                    label: '变压器容量(元/千伏安·月)',
                },
            ],
            formOptions: {
                forms: [
                    {
                        prop: 'month',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择月份',
                        options: monthOptions,
                    },
                    {
                        prop: 'waterMark',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择丰枯标志',
                        options: [
                            {key: 1, value: '枯水期'},
                            {key: 2, value: '丰水期'},
                            {key: 3, value: '平水期'}
                        ]
                    },
                    {
                        prop: 'elecType',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择用电分类',
                        options: [
                            {key: 1, value: '大工业用电'},
                            {key: 0, value: '一般工商业及其他用电'}
                        ]
                    },
                    {
                        prop: 'period',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择计费时段',
                        options: [
                            {key: 1, value: '峰时'},
                            {key: 2, value: '平时'},
                            {key: 3, value: '谷时'}
                        ]
                    },
                ]
            },
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                type: ''
            }
        };
    },
    methods: {
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        addHandle() {
            this.dialog = {
                title: '新建',
                currentId: undefined,
                visible: true,
                type: 'add'
            };
        },
        editHandle(id) {
            if (id) {
                this.dialog = {
                    currentId: id,
                    title: '编辑',
                    visible: true,
                    type: 'edit'
                };
            }
        },
        deleteHandle(id) {
            if (id) {
                this.$confirm('确认删除？', {
                    type: 'warning'
                }).then(() => {
                    const {selectedRows} = this;
                    const ids = [];
                    ids.push(id);
                    this.$client
                        .deleteElectricityPrices(ids)
                        .then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.$refs.searchTable.searchHandler();
                        })
                        .catch(() => {
                            this.$message({
                                message: '操作失败！',
                                type: 'error'
                            });
                        });
                });
            }
        },
        showDetail(id) {
            if (id) {
                this.dialog = {
                    currentId: id,
                    title: '详情',
                    visible: true,
                    type: 'detail'
                };
            }
        },
        handleValidate(type) {
            const {selectedRows} = this;
            const length = selectedRows.length;
            if (length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
                return false;
            }
            if ((type === 'edit' || type === 'detail') && length > 1) {
                this.$message({
                    message: '只允许对一条进行操作',
                    type: 'warning'
                });
                return false;
            }
            return true;
        },
        submitHandle() {
            this.$refs.editForm.submitHandle(rest => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        }
    }
};
</script>

