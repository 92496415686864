<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            :disabled="type === 'detail'"
        >
            <el-row :gutter="24">
                <el-col :span="5">
                    <el-form-item :label="'月份'" prop="month">
                        <el-select
                            v-model="formData.month"
                            :placeholder="'请选择'"
                            style="width: 100%"
                        >
                            <el-option
                                v-for="item in 12"
                                :key="item"
                                :label="item + '月'"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item :label="'丰枯标志'" prop="waterMark">
                        <el-select
                            v-model="formData.waterMark"
                            :placeholder="'请选择'"
                            style="width: 100%"
                        >
                            <el-option label="枯水期" :value="1"></el-option>
                            <el-option label="丰水期" :value="2"></el-option>
                            <el-option label="平水期" :value="3"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item :label="'用电分类'" prop="elecType">
                        <el-select
                            v-model="formData.elecType"
                            :placeholder="'请选择'"
                            style="width: 100%"
                        >
                            <el-option label="大工业用电" :value="1"></el-option>
                            <el-option label="一般工商业及其他用电" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <div class="spe_line"></div>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="5">
                    <el-form-item label="不满1kv(峰时)" prop="peakPriceOne">
                        <el-input-number
                            v-model="formData.peakPriceOne"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="1~10kv(峰时)" prop="peakPriceTwo">
                        <el-input-number
                            v-model="formData.peakPriceTwo"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="35~110kv(峰时)" prop="peakPriceThree">
                        <el-input-number
                            v-model="formData.peakPriceThree"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="110kv(峰时)" prop="peakPriceFour">
                        <el-input-number
                            v-model="formData.peakPriceFour"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="220kv及以上(峰时)" prop="peakPriceFive">
                        <el-input-number
                            v-model="formData.peakPriceFive"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="农网还贷基金(峰时)" prop="peakPriceNw">
                        <el-input-number
                            v-model="formData.peakPriceNw"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="重大水利工程建设基金(峰时)" prop="peakPriceZdsl">
                        <el-input-number
                            v-model="formData.peakPriceZdsl"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="大中型水库移民后期扶持基金(峰时)" prop="peakPriceDzxskym">
                        <el-input-number
                            v-model="formData.peakPriceDzxskym"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="地方水库移民后期扶持基金(峰时)" prop="peakPriceDfskym">
                        <el-input-number
                            v-model="formData.peakPriceDfskym"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="可再生能源电价附加(峰时)" prop="peakPriceKzsny">
                        <el-input-number
                            v-model="formData.peakPriceKzsny"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="地方水利建设基金(峰时)" prop="peakPriceDfsl">
                        <el-input-number
                            v-model="formData.peakPriceDfsl"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="政府性基金及附加合计(峰时)（元/千瓦时）">
                        <el-input-number
                            v-model="pJjTotal"
                            :controls="false"
                            style="width: 100%"
                            :disabled="true"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="5">
                    <el-form-item label="不满1kv(平时)" prop="flatPriceOne">
                        <el-input-number
                            v-model="formData.flatPriceOne"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="1~10kv(平时)" prop="flatPriceTwo">
                        <el-input-number
                            v-model="formData.flatPriceTwo"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="35~110kv(平时)" prop="flatPriceThree">
                        <el-input-number
                            v-model="formData.flatPriceThree"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="110kv(平时)" prop="flatPriceFour">
                        <el-input-number
                            v-model="formData.flatPriceFour"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="220kv及以上(平时)" prop="flatPriceFive">
                        <el-input-number
                            v-model="formData.flatPriceFive"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="农网还贷基金(平时)" prop="flatPriceNw">
                        <el-input-number
                            v-model="formData.flatPriceNw"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="重大水利工程建设基金(平时)" prop="flatPriceZdsl">
                        <el-input-number
                            v-model="formData.flatPriceZdsl"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="大中型水库移民后期扶持基金(平时)" prop="flatPriceDzxskym">
                        <el-input-number
                            v-model="formData.flatPriceDzxskym"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="地方水库移民后期扶持基金(平时)" prop="flatPriceDfskym">
                        <el-input-number
                            v-model="formData.flatPriceDfskym"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="可再生能源电价附加(平时)" prop="flatPriceKzsny">
                        <el-input-number
                            v-model="formData.flatPriceKzsny"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="地方水利建设基金(平时)" prop="flatPriceDfsl">
                        <el-input-number
                            v-model="formData.flatPriceDfsl"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="政府性基金及附加合计(平时)（元/千瓦时）">
                        <el-input-number
                            v-model="fJjTotal"
                            :controls="false"
                            style="width: 100%"
                            :disabled="true"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <div class="spe_line"></div>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="5">
                    <el-form-item label="不满1kv(谷时)" prop="valleyPriceOne">
                        <el-input-number
                            v-model="formData.valleyPriceOne"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="1~10kv(谷时)" prop="valleyPriceTwo">
                        <el-input-number
                            v-model="formData.valleyPriceTwo"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="35~110kv(谷时)" prop="valleyPriceThree">
                        <el-input-number
                            v-model="formData.valleyPriceThree"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="110kv(谷时)" prop="valleyPriceFour">
                        <el-input-number
                            v-model="formData.valleyPriceFour"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="220kv及以上(谷时)" prop="valleyPriceFive">
                        <el-input-number
                            v-model="formData.valleyPriceFive"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="农网还贷基金(谷时)" prop="valleyPriceNw">
                        <el-input-number
                            v-model="formData.valleyPriceNw"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="重大水利工程建设基金(谷时)" prop="valleyPriceZdsl">
                        <el-input-number
                            v-model="formData.valleyPriceZdsl"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="大中型水库移民后期扶持基金(谷时)" prop="valleyPriceDzxskym">
                        <el-input-number
                            v-model="formData.valleyPriceDzxskym"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="8">
                    <el-form-item label="地方水库移民后期扶持基金(谷时)" prop="valleyPriceDfskym">
                        <el-input-number
                            v-model="formData.valleyPriceDfskym"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="可再生能源电价附加(谷时)" prop="valleyPriceKzsny">
                        <el-input-number
                            v-model="formData.valleyPriceKzsny"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="地方水利建设基金(谷时)" prop="valleyPriceDfsl">
                        <el-input-number
                            v-model="formData.valleyPriceDfsl"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item label="政府性基金及附加合计(谷时)（元/千瓦时）">
                        <el-input-number
                            v-model="vJjTotal"
                            :controls="false"
                            style="width: 100%"
                            :disabled="true"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <div class="spe_line"></div>
                </el-col>
            </el-row>

            <el-row :gutter="24">
                <el-col :span="5">
                    <el-form-item label="最大需量(元/kW·月)" prop="maxDemandBasePrice">
                        <el-input-number
                            v-model="formData.maxDemandBasePrice"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="5">
                    <el-form-item label="变压器容量(元/kVA·月)" prop="transformerCapacityBasePrice">
                        <el-input-number
                            v-model="formData.transformerCapacityBasePrice"
                            :controls="false"
                            style="width: 100%"
                        >
                        </el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'ElectricityPriceForm',
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                month: null,
                waterMark: null,
                elecType: null,
                period: null,
                priceOne: undefined,
                priceTwo: undefined,
                priceThree: undefined,
                priceFour: undefined,
                priceFive: undefined,
                maxDemandBasePrice: undefined,
                transformerCapacityBasePrice: undefined
            }
        };
    },
    computed: {
        formRules() {
            return {
                month: [
                    {required: true, message: '请选择月份', trigger: 'blur'}
                ],
                waterMark: [
                    {required: true, message: '请选择丰枯标志', trigger: 'blur'}
                ],
                elecType: [
                    {required: true, message: '请选择用电分类', trigger: 'blur'}
                ],
                period: [
                    {required: true, message: '请选择计费时段', trigger: 'blur'}
                ],
                priceOne: [
                    {required: true, message: '请填写', trigger: 'blur'}
                ],
                priceTwo: [
                    {required: true, message: '请填写', trigger: 'blur'}
                ],
                priceThree: [
                    {required: true, message: '请填写', trigger: 'blur'}
                ],
                priceFour: [
                    {required: this.formData.elecType === 1, message: '请填写', trigger: 'blur'}
                ],
                priceFive: [
                    {required: this.formData.elecType === 1, message: '请填写', trigger: 'blur'}
                ],
                maxDemandBasePrice: [
                    {required: this.formData.elecType === 1, message: '请填写', trigger: 'blur'}
                ],
                transformerCapacityBasePrice: [
                    {required: this.formData.elecType === 1, message: '请填写', trigger: 'blur'}
                ]
            };
        },
        pJjTotal() {
            let t = (this.formData.peakPriceNw || 0) + (this.formData.peakPriceZdsl || 0) + (this.formData.peakPriceDzxskym || 0)
                + (this.formData.peakPriceDfskym || 0) + (this.formData.peakPriceKzsny || 0) + (this.formData.peakPriceDfsl || 0);
            return t;
        },
        fJjTotal() {
            let t = (this.formData.flatPriceNw || 0) + (this.formData.flatPriceZdsl || 0) + (this.formData.flatPriceDzxskym || 0)
                + (this.formData.flatPriceDfskym || 0) + (this.formData.flatPriceKzsny || 0) + (this.formData.flatPriceDfsl || 0);
            return t;
        },
        vJjTotal() {
            let t = (this.formData.valleyPriceNw || 0) + (this.formData.valleyPriceZdsl || 0) + (this.formData.valleyPriceDzxskym || 0)
                + (this.formData.valleyPriceDfskym || 0) + (this.formData.valleyPriceKzsny || 0) + (this.formData.valleyPriceDfsl || 0);
            return t;
        },
    },
    methods: {
        async initFormValue() {
            const {type, id} = this;
            if ((type === 'edit' || type === 'detail') && id != null) {
                this.getElectricityPrice(this.id);
            }
        },
        getElectricityPrice(id) {
            this.$client.getElectricityPrice(id).then(ret => {
                if (ret.code === 0) {
                    for (const key in ret.data) {
                        if (ret.data[key] == null) {
                            ret.data[key] = undefined;
                        }
                    }
                    this.formData = ret.data || {};
                } else {
                    this.$message({
                        message: ret.msg,
                        type: 'error'
                    });
                }
            });
        },
        submitHandle(cb) {
            this.$refs.form.validate(valid => {
                if (this.type === 'add') {
                    if (valid) {
                        this.$client.insertElectricityPrice(this.formData).then(() => {
                            this.$message({
                                message: '操作成功！',
                                type: 'success'
                            });
                            cb();
                        });
                    }
                } else if (valid) {
                    this.$client.updateElectricityPrice(this.id, this.formData).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        cb();
                    });
                }
            });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
/deep/ [label][form] {
    width: 100%;
    text-align: left;
}
.spe_line {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #d4d4d4;
    text-align: center;
    font-size: 16px;
    color: rgba(101, 101, 101, 1);
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
